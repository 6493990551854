import { Swipe, SwipeItem } from 'vant';
import { NavBar } from 'vant';
import { Icon } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Button } from 'vant';
import { Tab, Tabs } from 'vant';
import { Rate } from 'vant'
import { Form  } from 'vant' 
import { Field } from 'vant';
import { DropdownMenu, DropdownItem } from 'vant';
import { Calendar } from 'vant';
import { Picker } from 'vant';
import { Popup } from 'vant';
import { Lazyload } from 'vant';
import { Uploader } from 'vant';

export default {
    install(Vue) {
        Vue.use(Lazyload);
        Vue.use(Button);
        Vue.use(Popup);
        Vue.use(Picker);
        Vue.use(Calendar);
        Vue.use(Field);
        Vue.use(Form);
        Vue.use(Rate),
        Vue.use(Icon);
        Vue.use(NavBar);
        Vue.use(Cell).use(CellGroup);
        Vue.use(Swipe).use(SwipeItem);
        Vue.use(Tab).use(Tabs);
        Vue.use(DropdownMenu).use(DropdownItem);
        Vue.use(Uploader);
    }
}