import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vantdemand from './utils/demand.js'
import 'vant/lib/index.css'
import 'amfe-flexible'
// import axios from 'axios'
const app =createApp(App)
// app.config.globalProperties.$axios = axios

app.use(store).use(router).use(Vantdemand).mount('#app')
