<template>
  <router-view />
</template>
<script>
import { getToken } from "./utils/api.js";
import wx from "weixin-js-sdk";
import { onMounted, reactive } from "vue";
export default {
  setup() {
    const state = reactive({
      code: "",
    });
    onMounted(() => {
      init();
      init1();
    });
    const init = () => {
      const url = window.location.href;
      const data = { url: url };
      getToken(data).then((res) => {
        let resp = res.data.result;
        if (resp) {
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: resp.appId, // 必填，公众号的唯一标识
            timestamp: resp.timestamp, // 必填，生成签名的时间戳
            nonceStr: resp.nonceStr, // 必填，生成签名的随机串
            signature: resp.signature, // 必填，签名
            jsApiList: ["openLocation", "getLocation"], // 必填，需要使用的JS接口列表
          });
        }
      });
    };
    const init1 = () => {
      let local = window.location.href; // 获取页面url
      let appid = "wx30891823273c7bc6";
      state.code = getUrlCode().code;
      if (
        state.code === "" ||
        state.code === null ||
        state.code === undefined
      ) {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&connect_redirect=1&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      } else {
        // getOpenid(state.code);
        sessionStorage.setItem("code", state.code);
      }
    };
    const getUrlCode = () => {
      // 截取url中的code方法
      var url = location.search;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    };
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #f4f4f4;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
