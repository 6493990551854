import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Index",
    component: () =>import('../views/Index.vue'),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/game",
    name: "Game",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Game.vue"),
  },
  {
    path: "/shop",
    name: "Shop",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Shop.vue"),
  },{
    path:"/visit",
    name:"Visit",
    component: () => import("../views/Visit.vue")
  },{
    path:"/appointment",
    name:"Appointment",
    component:() => import('../views/Appointment.vue')
  },{
    path:'/gameView',
    name:'GameView',
    component:() => import('../views/GameView.vue')
  },{
    path:'/feedback',
    name:'Feedback',
    component:() => import('../views/Feedback.vue')
  },{
    path:'/resultPage',
    name:'ResultPage',
    component:() => import('../views/ResultPage.vue')
  }
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
