import service from '../utils/request'

//获取token
export function getToken(data) {
  return service({
    url: '/sdk/wx/webjsticket/',
    method: 'POST',
    applicationType: 'JSON',  //这一段表示，是post 请求，就将 请求参数转换成 JSON 格式
    data
  })
}
//获取code
export function getCode(data) {
  return service.request({
    method: 'POST',
    url: "/sdk/wx/pushmessage",
    applicationType: 'JSON',
    data
  })
}
export function uploadFeedback(code, data) {
  return service.request({
    method: 'POST',
    url: `/admin/v1/public/feedback/?code=${code}`,
    data
  })
}
export function uploadImgApi(fileObj) {
  const formData = new FormData()
  formData.append('feedback', fileObj.file)
  formData.append('type', 'wechat')
  return service.post(`/admin/v1/public/feedback/file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}